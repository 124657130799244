import { BylinePerson } from "design-system/components/primitives/byline/byline";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { PersonEntry } from "frontend/contentful/schema/semantics";
import { createPersonImageProps } from "../primitives/media-asset.props";
import { getPersonName } from "../utils/get-person-name";
import { useContentful } from "frontend/hooks/use-contentful";

export const createBylinePersonProps = createContentfulProps<
  PersonEntry,
  BylinePerson
>(({ entry, createEditAttributes }) => {
  const { getPersonLink } = useContentful();

  const props: BylinePerson = {
    name: getPersonName(entry),
    link: getPersonLink(entry),
    avatar: createPersonImageProps(entry),
    editAttributes: {
      name: createEditAttributes({ entry, fieldId: "firstName" }),
    },
  };

  if (entry?.fields.firstName || entry?.fields.displayName) return props;
});
