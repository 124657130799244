import { BylineProps } from "design-system/components/primitives/byline/byline";
import { ArticlePageEntry } from "frontend/contentful/schema/pages";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { createBylinePersonProps } from "../primitives/byline-person.props";

export const createBylineProps = createContentfulProps<
  ArticlePageEntry,
  BylineProps
>(({ entry, createEditAttributes }) => {
  const props: BylineProps = {
    type: "ShowAll",
    publicationDate: entry.fields.publicationDate,
    editAttributes: {
      publicationDate: createEditAttributes({
        entry,
        fieldId: "publicationDate",
      }),
    },
  };

  props.authors = [];
  entry.fields.authors?.forEach((person) => {
    const bylinePersonProps = createBylinePersonProps(person);
    if (bylinePersonProps) props.authors?.push(bylinePersonProps);
  });

  props.featuredPeople = [];
  entry.fields.relatedFaculty?.forEach((person) => {
    const bylinePersonProps = createBylinePersonProps(person);
    if (bylinePersonProps) props.featuredPeople?.push(bylinePersonProps);
  });

  if (props.featuredPeople?.length > 0) props.headshots = true;

  return props;
});
