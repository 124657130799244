import {
  ArticleTeaseProps,
  ArticleTeaseCategoryProps,
} from "design-system/components/primitives/article-tease/article-tease";
import { MediaAssetImageProps } from "design-system/components/primitives/media-asset/media-asset-image";
import {
  SearchHit,
  ModernSearchHit,
  LegacySearchHit,
  SearchDisplay,
} from "frontend/types";
import { stripHtml } from "../components/utils/strip-html";
import { isSearchHitType } from "./is-search-hit-type";
import { BylinePerson } from "design-system/components/primitives/byline/byline";

export function createSearchArticleTease(
  data?: SearchHit,
  thumbnailProps?: Partial<MediaAssetImageProps>,
) {
  if (isSearchHitType<ModernSearchHit>("modernArticle", data)) {
    return createModernSearchArticleTease(data);
  }
  if (isSearchHitType<LegacySearchHit>("legacyArticle", data)) {
    return createLegacySearchArticleTease(data, thumbnailProps);
  }
}

export function createLegacySearchArticleTease(
  data: LegacySearchHit,
  thumbnailProps?: Partial<MediaAssetImageProps>,
): ArticleTeaseProps {
  const props: ArticleTeaseProps = {
    type: "Article",
    title: data.HBSTitle,
    link: data.HBSLink,
    tease: data.HBSDescription,
    byline: {
      publicationDate: data.HBSPubDate,
      type: "ShowAll",
    },
  };

  if (data.HBSSource) {
    props.category = { label: data.HBSSource };
  }

  if (data.HBSByline) {
    let people = data.HBSByline;
    let authors = data.HBSByline;

    if (authors.startsWith("by ") || authors.startsWith("By ")) {
      authors = authors.substring(3);
    }

    if (authors.startsWith("by: ") || authors.startsWith("By: ")) {
      authors = authors.substring(4);
    }

    if (people.startsWith("re ") || people.startsWith("Re ")) {
      people = people.substring(3);
    }

    if (people.startsWith("re: ") || people.startsWith("Re: ")) {
      people = people.substring(4);

      if (people.indexOf(", ") > 0) {
        let peopleCleanup = "";

        people.split(", ").forEach((person) => {
          if (person.indexOf("@") > 0) return; // skip email addresses
          peopleCleanup = peopleCleanup
            ? peopleCleanup + ", " + person
            : person;
        });

        people = peopleCleanup;
      }
    }

    if (authors !== data.HBSByline) props.byline!.authors = [{ name: authors }];
    if (people !== data.HBSByline) {
      props.byline!.featuredPeople = [{ name: people }];
    }
  }

  if (data.HBSThumbnail) {
    props.image = {
      src: data.HBSThumbnail,
      ...thumbnailProps,
    };
  }

  return props;
}

export function createModernSearchArticleTease(
  data: ModernSearchHit | SearchDisplay,
): ArticleTeaseProps {
  let description = data.description;

  //Modern Search Hit with "legacy" subset only used on sitewide Search Pages
  if (data.subset?.includes("legacy") && !description) {
    description = data.body;
  }

  if (
    data.highlight?.description &&
    Array.isArray(data.highlight?.description)
  ) {
    description = data.highlight?.description[0];
  }

  if (data.highlight?.body && Array.isArray(data.highlight?.body)) {
    description = data.highlight?.body[0];
  }

  // Use the body as a last resort if no description is set
  if (!description && data.body) {
    description = data.body.substring(0, 500).trim();
  }

  const props: ArticleTeaseProps = {
    type: "Article",
    title: data.title,
    link: data.url,
    tease: description && stripHtml(description),
  };

  //if data type = ModernSearchHit then use data.display
  //if data type = SearchDisplay then use data
  //if data type = LegacySearchHit then use data.display
  if (isSearchHitType<SearchDisplay>("modern", data)) {
    if (data.display?.category) {
      props.category = data.display.category as ArticleTeaseCategoryProps;
    }

    if (data.display?.thumbnail?.src) {
      props.image = data.display.thumbnail;
    }

    if (data.display?.date) {
      props.byline = {
        publicationDate: data.display.date,
      };
    }

    if (data.display?.byline) {
      if (
        data.display.byline[0]?.label &&
        (data.display.byline[0].label.toLowerCase().startsWith("by") ||
          data.display.byline[0].label.toLowerCase().startsWith("re"))
      ) {
        // temporary add for supporting legacy byline data (legacy loader now in modern ES setup)
        const legacyByline = stripHtml(data.display.byline[0].label);

        let people = legacyByline;
        let authors = legacyByline;

        if (authors.startsWith("by ") || authors.startsWith("By ")) {
          authors = authors.substring(3);
        }

        if (authors.startsWith("by: ") || authors.startsWith("By: ")) {
          authors = authors.substring(4);
        }

        if (people.startsWith("re ") || people.startsWith("Re ")) {
          people = people.substring(3);
        }

        if (people.startsWith("re: ") || people.startsWith("Re: ")) {
          people = people.substring(4);

          if (people.indexOf(", ") > 0) {
            let peopleCleanup = "";

            people.split(", ").forEach((person) => {
              if (person.indexOf("@") > 0) return; // skip email addresses
              peopleCleanup = peopleCleanup
                ? peopleCleanup + ", " + person
                : person;
            });

            people = peopleCleanup;
          }
        }

        if (props.byline) {
          if (authors !== legacyByline) {
            props.byline.authors = [{ name: authors }];
            props.byline.type = "ShowAll";
          }
          if (people !== legacyByline) {
            props.byline.featuredPeople = [{ name: people }];
            props.byline.type = "ShowAll";
          }
        } else {
          if (authors !== legacyByline) {
            props.byline = { authors: [{ name: authors }], type: "ShowAll" };
          }
          if (people !== legacyByline) {
            props.byline = {
              featuredPeople: [{ name: people }],
              type: "ShowAll",
            };
          }
        }
      } else {
        const byline: Array<BylinePerson> = [];

        data.display.byline.forEach((author) => {
          if (author.label) {
            byline.push({ name: author.label, link: author.link });
          }
        });

        if (data.subset?.includes("artwork")) {
          props.byline = { authors: byline, type: "ShowAll" };
        } else if (props.byline) {
          props.byline.authors = byline;
        } else {
          props.byline = { authors: byline };
        }
      }

      if (data.display.downloadLink) {
        props.downloadLink = data.display.downloadLink;
      }
      if (data.display.caseHead) {
        props.display = {
          caseHead: data.display.caseHead,
          caseCategory: data.display.caseCategory,
        };
      }
    }
  } else {
    // TODO: Add category url support to modern search schema
    if (data.source) {
      props.category = { label: data.source /*, link: data.sourceUrl */ };
    } else if (data.contentType) {
      props.category = {
        label: data.contentType /*, link: data.contentTypeUrl */,
      };
    }
  }

  if (data.subset?.includes("artwork")) {
    props.variant = "artwork";
  }

  if (data.subset?.includes("participantStory")) {
    props.variant = "profile";
  }

  return props;
}
